
import {toast} from "react-toastify";

export const copyToClipboard = (content)=>{
    var input = document.createElement('textarea');
    input.innerHTML = content;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    // return result;

    toast('🦄 Copied', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}


