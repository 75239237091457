import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import config from "../../config";
import {
  getFactoryContract,
  getMarketPlaceContract,
  getWeb3,
} from "../../utils/contract";
import { getNFTByID } from "../../apollo/queries";
import moment from "moment";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import { toast } from "react-toastify";

const Item = () => {
  const search = useLocation().search;
  const nftId = new URLSearchParams(search).get("id");
  const [nft, setNft] = useState();
  const [metaData, setMetaData] = useState();
  const [isLoading, setLoading] = useState();

  const [showBuyModal, setShowBuyModal] = useState();

  const { account } = useWallet();

  useEffect(() => {
    if (nftId) {
      loadNFT();
    } else {
      goTo404();
    }
  }, [nftId]);

  const loadNFT = async () => {
    const _nft = await getNFTByID(nftId);

    if (_nft) {
      setNft(_nft);
      setMetaData(_nft.metadata);
    } else {
      goTo404();
    }
  };
  const goTo404 = () => {
    window.location = "/404";
  };

  const PutOnSaleModal = ({ show, toggle }) => {
    const [sellingAmount, setSellingAmount] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setLoading] = useState();
    const handlePutOnSale = async (nftId, price) => {
      setErrorMsg("");
      setLoading(true);
      const nftFactory = getFactoryContract();
      const marketPlaceContract = getMarketPlaceContract();

      const isApproved = await nftFactory.methods
        .isApprovedForAll(account, config.NFT_MARKETPLACE)
        .call();

      if (!isApproved) {
        try {
          await nftFactory.methods
            .setApprovalForAll(config.NFT_MARKETPLACE, true)
            .send({ from: account });
        } catch (e) {
          setLoading(false);
          return;
        }
      }

      try {
        await marketPlaceContract.methods
          .listNFT(nftId, price)
          .send({ from: account });

        setLoading(false);

        toast("Successfully Put on Sale", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        window.location.reload()
        toggle();

      } catch (e) {
        console.log("Sasasa", e);
      }
    };

    return (
      <div
        className="modal modal-open "
        role="dialog"
        style={{
          background: "#000000a6",
          display: show ? "block" : "none",
        }}
        onClick={() => {
          toggle();
        }}
        aria-hidden="false"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          onClick={(e) => {
            e.stopPropagation();
          }}
          pointerEv
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              onClick={() => {
                toggle();
              }}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="modal-body space-y-20 p-40">
              <h2
                style={{
                  fontSize: 30,
                  // textAlign: "left",
                }}
              >
                {" "}
                Put on Sale
              </h2>
              <p>
                Enter The Desired Amount of Sale in
                <span className="color_black"> BNB</span>
              </p>
              <input
                type="number"
                value={sellingAmount}
                onChange={(e) => {
                  setSellingAmount(e.target.value);
                }}
                type="number"
                className="form-control"
                placeholder="i.e 0.75 BNB"
              />

              {sellingAmount > 0 ? (
                <>
                  {" "}
                  <div className="hr" />
                  <div className="d-flex justify-content-between">
                    <p>Sale Amount </p>
                    <p className="text-right color_black txt _bold">
                      {" "}
                      {sellingAmount} BNB{" "}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>NFT Shell Fee (2.5%):</p>
                    <p className="text-right color_black txt _bold">
                      {" "}
                      {(sellingAmount * 2.5) / 100} BNB{" "}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p> Your will get:</p>
                    <p className="text-right color_black txt _bold">
                      {" "}
                      {(sellingAmount * 97.5) / 100} BNB{" "}
                    </p>
                  </div>
                </>
              ) : null}

              <a
                onClick={() => {
                  if (sellingAmount > 0) {
                    handlePutOnSale(nftId, Web3.utils.toWei(sellingAmount));
                  } else {
                    setErrorMsg("Please Enter A Valid Amount");
                  }
                }}
                className="btn btn-primary w-full"
              >
                {isLoading ? (
                  <div class="spinner-border" role="status"></div>
                ) : (
                  "Put on Sale"
                )}
              </a>

              <span
                className="text-rose-500	"
                style={{
                  color: "#c90000",
                  fontWeight: "bold",
                }}
              >
                {errorMsg}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
    return null;
  };

  const SendNFTPanel = () => {
    const [isError, setError] = useState();
    const [isLoading, setLoading] = useState(false);
    const [walletInput, setWalletInput] = useState();

    const handleSend = async () => {
      setError("");
      if (!walletInput) {
        setError("Please Enter Wallet Address");
        return;
      }

      if (!Web3.utils.isAddress(walletInput)) {
        setError("Please Enter A Valid Wallet Address");
        return;
      }

      setLoading(true);

      try {
        await getFactoryContract()
          .methods.transferFrom(account, walletInput, nftId)
          .send({ from: account });

        toast("Successfully Sent NFT", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        window.location.reload();
      } catch (err) {}
      setLoading(false);
    };
    return (
      <>
        <span
          style={{
            textAlign: "start",
            float: "left",
          }}
        >
          Enter Wallet Address
        </span>

        <input
          onChange={(e) => {
            setWalletInput(e.target.value);
          }}
          value={walletInput}
          type="text"
          style={{
            width: "100%",
            marginTop: 0,
          }}
          className="form-control"
          placeholder="e. g. `0xBB2314557....`"
        />

        {isError ? (
          <span
            className="form-control"
            style={{
              // textAlign: "start",
              fontWeight: "bold",
              color: "#cb1a1a",
              // float: "left",
            }}
          >
            Please Enter A Valid Address
          </span>
        ) : null}

        <a
          href="#"
          className="btn btn-primary btn-lg w-full"
          data-toggle="modal"
          onClick={() => {
            handleSend();
          }}
        >
          {isLoading ? (
            <div class="spinner-border" role="status"></div>
          ) : (
            "Transfer"
          )}
        </a>
      </>
    );
  };

  const renderHistory = () => {
    if (nft.transfers.length == 0) {
      return <p>No History</p>;
    }

    const items = [];

    for (let transfer of nft.transfers) {
      items.push(
        <div
          className="creator_item creator_card
        space-x-10"
        >
          <div className="avatars space-x-10">
            {/* <div className="media">
              <div className="badge">
                <img src="assets/img/icons/Badge.svg" alt="" />
              </div>
              <a href="/profile">
                <img
                  src="assets/img/avatars/avatar_1.png"
                  alt="Avatar"
                  className="avatar
                avatar-md"
                />
              </a>
            </div> */}
            <div>
              <p className="color_black">
                Transfer To
                <br />{" "}
                <a
                  className="color_black txt
                _bold"
                target="_blank"

                href={`https://bscscan.com/address/${transfer.to}`}
                >
                  {transfer.to}
                </a>{" "}
                <br />
                from <br />
                <a
                  className="color_black txt
                _bold"
                href={`https://bscscan.com/address/${transfer.from}`}
                target="_blank"
                >
                  {transfer.from}
                </a>
              </p>
              <p className="color_black">at</p>
              <span className="date color_text">{moment(transfer.createdAt*1000).format("DD MMM YYYY hh:mm a")}</span>
            </div>
          </div>
        </div>
      );
    }

    return items;
  };

  const handleRemoveNFTFromSale = async()=>{

    try{    await getMarketPlaceContract().methods.cancelOffer(parseInt(nft.offer.id)).send({from:account});
    window.location.reload()

    }catch(err){

    }
  }

  const handleBuy = async()=>{
    try{
      await getMarketPlaceContract().methods.fillOffer(parseInt(nft.offer.id)).send({from:account,value:nft.offer.price})
      toast("Successfully Bought", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      window.location.reload()
    }catch(err){
      console.log("errrr",err)
    }
  }

  const renderMainButton = ()=>{
    if(nft.offer && !nft.offer.isCancelled){
      if(nft.offer.offeredBy.toLowerCase() ===account.toLowerCase()){
        return <a
        className="btn btn-danger btn-lg"
        data-toggle="modal"
        onClick={()=>{handleRemoveNFTFromSale()}}
        data-target="#popup_buy"
      >
   
       Remove NFT From Sale
      </a>
      }else{
        return   <a
        onClick={() => {
          handleBuy();
        }}
        className="btn btn-outline btn-lg ml-3"
        data-toggle="modal"
        data-target="#popup_buy"
      >
        {" "}
       Buy Now
      </a>
      }
    }
    
    return <a
    href="#"
    className="btn btn-secondary btn-lg"
    data-toggle="modal"
    data-target="#popup_buy"
  >
    {" "}
    Not Available To Buy
  </a>
  }



  return (
    <div className="container">
      <PutOnSaleModal
        show={showBuyModal}
        isLoading={isLoading}
        toggle={() => {
          setShowBuyModal(!showBuyModal);
        }}
      />

      {nft && metaData ? (
        <>
          {" "}
          <a href="/" className="btn btn-white btn-sm my-40">
            Back to home
          </a>
          <div className="item_details">
            <div className="row sm:space-y-20">
              <div className="col-lg-6">
                <img
                  className="item_img"
                  src={`${config.IPFS_CLIENT}/${metaData.image}`}
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="space-y-20">
                  <div className="dropdown">
                    <div className="d-flex justify-content-between">
                      <div className="space-x-10 d-flex align-items-center">
                        <div className="share">
                          <div className="icon">
                            {/* <a > <i className="ri-share-line" />
                        </a> */}
                            <i className="ri-share-line" />
                          </div>
                          <div className="dropdown__popup">
                            <ul className="space-y-10">
                              <li>
                                {" "}
                                <a href="/item">
                                  {" "}
                                  <i className="ri-facebook-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a href="/item">
                                  {" "}
                                  <i className="ri-messenger-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a href="/item">
                                  {" "}
                                  <i className="ri-whatsapp-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a href="/item">
                                  {" "}
                                  <i className="ri-youtube-line" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="more">
                          <div className="icon">
                            <a href="#">
                              {" "}
                              <i className="ri-more-fill" />
                            </a>
                          </div>
                          <div className="dropdown__popup">
                            <ul className="space-y-10">
                              <li>
                                <a
                                  href="#"
                                  className="space-x-10
															d-flex"
                                  data-toggle="modal"
                                  data-target="#popup_report"
                                >
                                  <i className="ri-flag-line" />
                                  <span> Report</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <button
                          className="btn btn-white btn-sm
										dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          View proof of authenticity
                        </button>
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item"
                            href={`https://ipfs.infura.io/ipfs/${nft.uri}`}
                            target="_blank"
                          >
                            <span>
                              <img
                                src="assets/img/icons/ipfs.svg"
                                width={20}
                                alt=""
                              />
                              View on IPFS
                            </span>
                            <i className="ri-external-link-line color_brand" />
                          </a>
                          <a
                            className="dropdown-item"
                            href={`${config.BSC_SCAN}token/${config.NFT_FACTORY}?a=${nft.token_id}`}
                            target="_blank"
                          >
                            <span>
                              <img
                                src="https://bscscan.com/images/favicon.ico"
                                width={20}
                                alt=""
                              />
                              View on BSCSCAN
                            </span>
                            <i className="ri-external-link-line color_brand" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="space-y-20">
                      <h1
                        style={{
                          fontSize: 30,
                          textAlign: "left",
                        }}
                      >
                        {metaData && metaData.name}
                      </h1>
                      <div
                        className="d-flex justify-content-between
											mb-30_reset"
                      >
                        <ul
                          className="nav nav-tabs d-flex space-x-10 mb-30"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="btn btn-white btn-sm active"
                              data-toggle="tab"
                              href="#tabs-1"
                              role="tab"
                            >
                              Details
                            </a>
                          </li>
                          {/* <li className="nav-item">
                            <a
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              href="#tabs-2"
                              role="tab"
                            >
                              Bids
                            </a>
                          </li> */}
                          <li className="nav-item">
                            <a
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              href="#tabs-3"
                              role="tab"
                            >
                              History
                            </a>
                          </li>

                          {account &&
                          nft.owner.toLowerCase() === account.toLowerCase() ? (
                            <li className="nav-item">
                              <a
                                className="btn btn-white btn-sm"
                                data-toggle="tab"
                                href="#tabs-4"
                                role="tab"
                              >
                                Transfer
                              </a>
                            </li>
                          ) : null}
                        </ul>
                        {/* Tab panes */}
                      </div>
                      <div className="hr" />
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="tabs-1"
                          role="tabpanel"
                        >
                          <p>{metaData && metaData.description}</p>
                        </div>
                        <div className="tab-pane" id="tabs-2" role="tabpanel">
                          <p>No active bids yet</p>
                        </div>
                        <div
                          className="tab-pane space-y-20"
                          id="tabs-3"
                          role="tabpanel"
                        >
                          {renderHistory()}

                          {/* <div
                            className="creator_item creator_card
													space-x-10"
                          >
                            <div className="avatars space-x-10">
                              <div className="media">
                                <div className="badge">
                                  <img
                                    src="assets/img/icons/Badge.svg"
                                    alt=""
                                  />
                                </div>
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_1.png"
                                    alt="Avatar"
                                    className="avatar
																	avatar-md"
                                  />
                                </a>
                              </div>
                              <div>
                                <p className="color_black">
                                  Bid accepted{" "}
                                  <span className="color_brand">1 ETH</span> by{" "}
                                  <a
                                    className="color_black txt
																	_bold"
                                    href="/profile"
                                  >
                                    ayoub
                                  </a>
                                </p>
                                <span className="date color_text">
                                  28/06/2021, 12:08
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="creator_item creator_card
													space-x-10"
                          >
                            <div className="avatars space-x-10">
                              <div className="media">
                                <div className="badge">
                                  <img
                                    src="assets/img/icons/Badge.svg"
                                    alt=""
                                  />
                                </div>
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_2.png"
                                    alt="Avatar"
                                    className="avatar
																	avatar-md"
                                  />
                                </a>
                              </div>
                              <div>
                                <p className="color_black">
                                  Bid accepted{" "}
                                  <span className="color_brand">3 ETH</span> by{" "}
                                  <a
                                    className="color_black txt
																	_bold"
                                    href="/profile"
                                  >
                                    monir
                                  </a>
                                </p>
                                <span className="date color_text">
                                  22/05/2021, 12:08
                                </span>
                              </div>
                            </div>
                          </div> */}
                        </div>

                        <div
                          className="tab-pane space-y-20"
                          id="tabs-4"
                          role="tabpanel"
                        >
                          <p>Send This NFTs from here</p>

                          <SendNFTPanel />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="numbers">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="space-y-5">
                          <p className="color_text">Created At</p>
                          <h4>
                            {/* 2.4000{" "} */}
                            <span className="">
                              {moment(nft.createdAt * 1000).format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </span>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="space-y-5">
                          <p className="color_text">Created By</p>
                          <h4>
                            {/* 2.4000{" "} */}
                            <a
                              target="_blank"
                              className=" hoverable"
                              href={`${config.BSC_SCAN}address/${metaData.creator}`}
                            >
                              {`${metaData.creator.substring(0, 12)}...`}
                            </a>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="space-y-5">
                          <p className="color_text">Owned By</p>
                          <h4>
                            {/* 2.4000{" "} */}
                            <a
                              target="_blank"
                              className=" hoverable"
                              href={`${config.BSC_SCAN}address/${nft.owner}`}
                            >
                              {`${nft.owner.substring(0, 12)}...`}
                            </a>
                          </h4>
                        </div>
                      </div>


                  
                    </div>

                   {nft.offer?<> <div className="row mt-4">
                      <div className="col-lg-4">
                        <div className="space-y-5">
                          <p className="color_text">Sale Price </p>
                          <h4>
                            {/* 2.4000{" "} */}
                            <span className="">
                              {Number(Web3.utils.fromWei(nft.offer.price)).toFixed(4)} BNB
                            </span>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="space-y-5">
                          <p className="color_text">Put on Sell at</p>
                          <h4>
                            {/* 2.4000{" "} */}
                            <span className="">
                              {moment(nft.offer.createdAt * 1000).format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </span>
                          </h4>
                        </div>
                      </div>

                 

                  
                    </div></>:null}
                  </div>
                  <div className="hr2" />

                  <div className="d-flex space-x-20">
                    {account &&
                    nft.owner.toLowerCase() === account.toLowerCase() ? (
                      <div>
                        <a
                          href="#"
                          className="btn btn-primary btn-lg"
                          data-toggle="modal"
                          data-target="#popup_buy"
                        >
                          {" "}
                          Owned By You
                        </a>

                        <a
                          onClick={() => {
                            setShowBuyModal(true);
                          }}
                          className="btn btn-outline btn-lg ml-3"
                          data-toggle="modal"
                          data-target="#popup_buy"
                        >
                          {" "}
                          Put On Sale
                        </a>
                      </div>
                    ) : (
                      renderMainButton()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );



};

export default Item;
