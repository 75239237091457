

/* import the ipfs-http-client library */
import { create } from 'ipfs-http-client';
import config from '../config';
import axios from "axios"
/* Create an instance of the client */

export const client = create(config.IPFS)


export const uploadFile=(file)=>{

    return client.add(file)
}




export const getMetaFile =async(hash)=>{
    const resp  = await axios.get(`${config.IPFS_CLIENT}/${hash}`) 
    console.log("resssp",resp)
    return resp.data
}


export const loadAllMetaData = async(rawNfts)=>{
    const loadedData = []

    for(let rawnft of rawNfts){
        let metadata  = await getMetaFile(rawnft.uri);

        loadedData.push({
            ...rawnft,
            metadata
        })
    }

    return loadedData;
}