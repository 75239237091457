
// import {useWallet} from "use-wallet"
import { useWeb3Context } from 'web3-react'

const Footer = ()=>{
  const  context = useWeb3Context()
  const {account} = context
     
    return <footer className="footer__1">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div style={{
            "text-align-last":"justify"
          }}>
            <a href="/">
              <img src="/logos/logo.png" width={250} style={{
                borderRadius:12,
                marginBottom:5
              }} alt="logo" id="" />
            </a>
          
            <p className="footer__text">
            Get instant loans by putting your NFTs as collateral. Borrow up to 50% of the value of your NFTs without selling them. #NFT marketplace #BSC
          </p>
          </div>
     

          <div>
            <ul className="footer__social space-x-10 mb-40">
              {/* <li>
                <a href="Home3.html"> <i className="ri-facebook-line" /> </a>
              </li> */}
              <li>
                <a href="https://twitter.com/shellnft"> <i className="ri-twitter-line" /> </a>
              </li>

              <li>
                <a href="https://t.me/nftshell"> <i className="ri-telegram-line" /> </a>
              </li>
              {/* <li>
                <a href="Home3.html"> <i className="ri-whatsapp-line" /> </a>
              </li> */}
              {/* <li>
                <a href="Home3.html"> <i className="ri-youtube-line" /> </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-6">
          <h6 className="footer__title">Quick Links</h6>
          <ul className="footer__list">
            <li><a href="/"> Home  </a></li>
            <li><a href="/explore"> Explore </a></li>
            {account?<li><a href={`/user?account=${account}`}> Your Profile </a></li>:null}
            <li><a href="/create"> Create  </a></li>
          </ul>
        </div>
        <div className="col-lg-2 col-6">
          <h6 className="footer__title">Contact</h6>
          <ul className="footer__list">
            {/* <li><a href="#"> Discord </a></li> */}
            <li><a href="https://t.me/nftshell"> Telegram </a></li>
            <li><a href="#"> Email </a></li>
            <li><a href="https://twitter.com/shellnft"> Twitter </a></li>
            {/* <li><a href="#"> Medium </a></li> */}

          </ul>
        </div>
     
      </div>
      <p className="copyright text-center">
       NFT SHELL Copyright © 2021. 
      </p>
    </div>
  </footer>

}

export default Footer;