import ComingSoonComponent from "../../components/ComingSoon";

const ComingSoon = () => {
 


  return (
    <div>
      <ComingSoonComponent/>
    </div>
  );
};

export default ComingSoon;
