import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
  } from "@apollo/client";
import config from "../config";


export const client = new ApolloClient({
    uri: config.APOLLO_CLIENT,
    cache: new InMemoryCache()
  });