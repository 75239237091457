


const IPFSClients = [
    "https://ipfs.infura.io/ipfs",
    "https://gateway.pinata.cloud/ipfs",
    "https://cloudflare-ipfs.com/ipfs",
  ];


const MAIN_RPCS = [
  "https://bsc-dataseed2.defibit.io/","https://bsc-dataseed3.defibit.io/","https://bsc-dataseed4.defibit.io/","https://bsc-dataseed2.ninicoin.io/","https://bsc-dataseed3.ninicoin.io/","https://bsc-dataseed4.ninicoin.io/","https://bsc-dataseed1.binance.org/","https://bsc-dataseed2.binance.org/","https://bsc-dataseed3.binance.org/","https://bsc-dataseed4.binance.org/","https://bsc-dataseed.binance.org/","https://bsc-dataseed1.defibit.io/","https://bsc-dataseed1.ninicoin.io/"
  ];


const TEST_RPCS = [
  "https://data-seed-prebsc-1-s1.binance.org:8545/","https://data-seed-prebsc-2-s1.binance.org:8545/","https://data-seed-prebsc-1-s2.binance.org:8545/","https://data-seed-prebsc-2-s2.binance.org:8545/","https://data-seed-prebsc-1-s3.binance.org:8545/","https://data-seed-prebsc-2-s3.binance.org:8545/"
];
  

const getIPFSClient = ()=>{
   
      return IPFSClients[Math.floor(Math.random()*IPFSClients.length)];

}

const getRandomItem = (list)=>{
   
  return list[Math.floor(Math.random()*list.length)];

}

// const TEST_CONFIG = {
//   // "0x44fdb1C5016C81E646EF95a087817D512332034e"
//   CHAIN_ID: 97,
//     CHAIN_NAME: "bsc%20testnet",
//     NFT_FACTORY: "0x8CCE404D36DfA8210D61015fDD2acb8849EBE049",
//     NFT_MARKETPLACE :"0xAAC0cc1e8eDcEe4E6CE32339b5207F2694df433a",
//     BSC_SCAN:"https://testnet.bscscan.com/",
//     RPC:getRandomItem(TEST_RPCS)



// }



const TEST_CONFIG = {
  CHAIN_ID: 4,
    CHAIN_NAME: "rinkeby",
    NFT_FACTORY: "0x4E694454b6a632B5f3aBc45cFceb9308aa15cf1C",
    NFT_MARKETPLACE :"0x86c07f67C2abC5D49ad6FbCBe405244bAD9Ad6aa",
    BSC_SCAN:"https://testnet.bscscan.com/",
    RPC:"https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    APOLLO_CLIENT:"https://api.thegraph.com/subgraphs/name/shahzeb8285/nftshellfactory"


}
const MAIN_CONFIG = {
  CHAIN_ID: 56,
    CHAIN_NAME: "bsc",
    NFT_FACTORY: "0xE39CCAEaB7eABC6c4824B4Da34585ad5F1e2C308",
    BSC_SCAN:"https://bscscan.com/",
    NFT_MARKETPLACE :"0xC156F66fC1beCD69a38fa73474944dbDBBb3F686",
    APOLLO_CLIENT:"https://api.thegraph.com/subgraphs/name/shahzeb8285/nftshellfactory",

    RPC:getRandomItem(MAIN_RPCS)

}



const CONFIG = MAIN_CONFIG

export default {
    IPFS: "https://ipfs.infura.io:5001/api/v0",
    MORALIS_API_KEY: "bydthUj7vLkMsBvUlgt51uqXvPbiEfLOoFgGYojgMxCMr0tZnInN4wITPFOXg1e1",
    IPFS_CLIENT:getIPFSClient(),

  ...CONFIG

  };
  