import { client } from "./clients";
import { gql } from "@apollo/client";
import { loadAllMetaData, getMetaFile } from "../utils/ipfs";

export const getMyNFTs = async (account) => {
  const query = `{
        nfts(where:{owner:"${account}"}) {
            id
            owner
            creator
            uri
            createdAt
           
        }
       
      }
    `;

  const resp = await client.query({
    query: gql`
      ${query}
    `,
  });

  let rawNFTs = [];

  for (let item of resp.data.nfts) {
    let nft = {
      ...item,
    };
    nft.id = parseInt(nft.id, 16);
    rawNFTs.push(nft);
  }

  let finalNFTs = await loadAllMetaData(rawNFTs);

  return finalNFTs;
};

export const getNFTByID = async (nftId) => {
  const query = `{
    nft(id:"0x${nftId.toString(16)}"){
      id,
      owner
      creator
      uri
      createdAt,

      transfers(first:3){
        from
        to
        tokenId
        createdAt
      }
      
    }

    offers(where:{nft:"0x${nftId.toString(16)}"}){
      id
      price
      isCancelled
      isFullFilled
      createdAt
      offeredBy
    }
   
  }
  
    `;

  const resp = await client.query({
    query: gql`
      ${query}
    `,
  });

  let offers = [];
  let _offer = null;
  if (resp.data.nft) {
    let nft = await getMetaFile(resp.data.nft.uri);
    console.log("pontttt", resp.data);

    if (resp.data.offers) {
      for (let offer of resp.data.offers) {
        if (!offer.isCancelled && !offer.isFullFilled) {
          _offer = offer;
        } else {
          offers.push(offer);
        }
      }
    }
    return { metadata: nft, ...resp.data.nft, offers, offer: _offer };
  }

  return null;
};

export const getRecentlyMinted = async (noOfNFT) => {
  const query = `{
    nfts(first:${noOfNFT}){
      id
      owner
      uri
      creator
      offers(where:{isCancelled : false, isFullFilled: false}){
        id
      	createdAt
      	price
      }
    }
  }
  
    `;

  const resp = await client.query({
    query: gql`
      ${query}
    `,
  });

  let rawNFTs = [];

  for (let item of resp.data.nfts) {
    let nft = {
      ...item,
    };
    let offer = null
    if(item.offers){
      offer = item.offers[0]
    }


    if(offer){
      nft.offer = offer
    }

    delete nft.offers

    nft.id = parseInt(nft.id, 16);
    rawNFTs.push(nft);
  }

  let finalNFTs = await loadAllMetaData(rawNFTs);
  console.log("qwertyyy",finalNFTs)

  return finalNFTs;
};
