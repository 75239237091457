import { useEffect } from "react";
import config from "../../config";
import moment from "moment";
import Web3 from "web3";

const NFTCard = ({ data }) => {
  const metaData = data.metadata;

  return (
    <div
      className="card__item   hoverable"
      onClick={() => {
        window.location = `/item?id=${data.id}`;
      }}
    >
      <div className="card_body space-y-10">
        <a>
          <div className="card_head">
            <img alt="" src={`${config.IPFS_CLIENT}/${metaData.image}`} />
          </div>
          <h6 className="card_title">
            <a className="color_black">{metaData.name}</a>
            <p
              className="avatars_name
              txt_sm cardDescription"
            >
              {`${metaData.description}`}
            </p>
          </h6>
          <div
            className="card_footer d-block
      space-y-10"
          >
            <div
              className="d-flex
        justify-content-between
        align-items-center"
            >
              <div
                className="creators
          space-x-10"
              >
                <div
                  className="avatars
            -space-x-20"
                >
                  {/* <a href="/profile">
                <img src="assets/img/avatars/avatar_1.png" alt="Avatar" className="avatar
                avatar-sm" />
              </a> */}
                  <a href="/profile">
                    <img
                      src="assets/img/avatars/avatar_2.png"
                      alt="Avatar"
                      className="avatar
                avatar-sm"
                    />
                  </a>
                </div>
                <a href="/profile">
                  <p
                    className="avatars_name
              txt_sm"
                  >
                    {`${metaData.creator.substring(0, 12)}...`}
                  </p>
                </a>
              </div>
              <a href="/profile#" className="space-x-3">
                <p
                  className="color_green
            txt_sm"
                >
                  Token ID: {data.id}
                </p>
              </a>
            </div>

            <div style={{
              visibility:data.offer?"visible":"hidden"
            }}>
              <div className="hr mb-2" />
              <div
                className="d-flex
        align-items-center
        space-x-10"
              >
                {/* <i className="ri-vip-crown-line" /> */}
                <p
                  className="color_text
          txt_sm"
                  style={{ width: "auto" }}
                >
                  Sale Price
                </p>
                <span
                  className="color_black
          txt_sm"
                >
                  {data.offer
                    ? Number(Web3.utils.fromWei(data.offer.price)).toFixed(4)
                    : ""}{" "}
                  BNB
                </span>

                <a
                  className="btn btn-sm btn-primary"
                  href={`/item?id=${data.id}`}
                >
                  Buy Now
                </a>
              </div>
            </div>

            <div className="hr" />

            <div
              className="d-flex
        align-items-center
        space-x-10"
            >
              <i className="ri-vip-crown-line" />
              <p
                className="color_text
          txt_sm"
                style={{ width: "auto" }}
              >
                Created At
              </p>
              <span
                className="color_black
          txt_sm"
              >
                {moment(metaData.createdAt).format("DD-MM-YYYY hh:ss a")}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default NFTCard;
