import logo from "./logo.svg";
import "./App.css";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import $ from "jquery";

import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Item from "./pages/Item";
import "./loader";
import { useEffect } from "react";
import Create from "./pages/Create";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import NotFound from "./pages/404";
import Explore from "./pages/Explore";
import { useWallet, UseWalletProvider } from "use-wallet";
import config from "./config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ComingSoon from "./pages/ComingSoon";



function App() {
  const jqueryCode = () => {
    // header

    (function () {
      const header = $(".js-header"),
        items = header.find(".js-header-item"),
        burger = header.find(".js-header-burger"),
        mobile = header.find(".js-header-mobile");

      items.each(function () {
        const item = $(this),
          head = item.find(".js-header-head"),
          body = item.find(".js-header-body");

        head.on("click", function (e) {
          e.stopPropagation();

          if (!item.hasClass("active")) {
            items.removeClass("active");
            item.addClass("active");
          } else {
            items.removeClass("active");
          }
        });

        body.on("click", function (e) {
          e.stopPropagation();
        });

        $("html, body").on("click", function () {
          items.removeClass("active");
        });
      });

      burger.on("click", function (e) {
        e.stopPropagation();

        burger.toggleClass("active");
        mobile.toggleClass("visible");
      });
    })();

    $("#upload_file").click(function () {
      $("#imgUpload").trigger("click");
    });

    // notifications popup
    (function () {
      const header = $(".js-header"),
        popup = header.find(".notifications_popup"),
        icon = header.find(".js-notifications-icon");

      icon.on("click", function (e) {
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();
    //  change log
    (function () {
      const changelog = $(".changelog"),
        log = changelog.find(".overflow_log"),
        more = changelog.find(".more_log"),
        text = changelog.find(".change_text");
      text.innerHTML = "New Heading";
      more.on("click", function (e) {
        e.stopPropagation();
        log.toggleClass("visible");
        text.innerHTML = "New Heading";
      });
    })();
    // avatar popup
    (function () {
      const header = $(".js-header"),
        popup = header.find(".avatar_popup"),
        icon = header.find(".header__avatar");

      icon.on("click", function (e) {
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();

    $(".dark").on("click", function (e) {
      $(".body").addClass("is__dark");
      $(".light").removeClass("is_active");
      $(".dark").addClass("is_active");
      document.getElementById("logo_js").src =
        "assets/img/logos/Logo_white.svg";
      document.getElementById("logo_js_f").src =
        "assets/img/logos/Logo_white.svg";
    });
    $(".light").on("click", function (e) {
      $(".body").removeClass("is__dark");
      $(".light").addClass("is_active");
      $(".dark").removeClass("is_active");
      document.getElementById("logo_js").src = "assets/img/logos/Logo.svg";
      document.getElementById("logo_js_f").src = "assets/img/logos/Logo.svg";
    });

    // menu popup
    (function () {
      const header = $(".js-header"),
        popup = header.find(".menu__popup"),
        icon = header.find(".has_popup");

      icon.on("click", function (e) {
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();
    (function () {
      const header = $(".js-header"),
        popup = header.find(".menu__popup2"),
        icon = header.find(".has_popup2");
      icon.on("click", function (e) {
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();

    //  share popup
    (function () {
      const share = $(".share"),
        popup = share.find(".dropdown__popup"),
        icon = share.find(".icon");

      share.on("click", function (e) {
        console.log("cl11lllllllll");
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      icon.on("click", function (e) {
        console.log("1");
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();
    //  more popup
    (function () {
      console.log("wdfsfvbscvb");
      const share = $(".more"),
        popup = share.find(".dropdown__popup"),
        icon = share.find(".icon");

      icon.on("click", function (e) {
        e.stopPropagation();
        popup.toggleClass("visible");
      });
      $(document).on("click", "body", function (e) {
        if (!$(e.target).is(".visible")) popup.removeClass("visible");
      });
    })();

    // profile Image
    $("#profileImage").click(function (e) {
      $("#imageUpload").click();
    });

    function fasterPreview(uploader) {
      if (uploader.files && uploader.files[0]) {
        $("#profileImage").attr(
          "src",
          window.URL.createObjectURL(uploader.files[0])
        );
      }
    }

    $("#imageUpload").change(function () {
      fasterPreview(this);
    });
  };

  useEffect(() => {
    jqueryCode();
  }, []);
  
  return (
    <UseWalletProvider
      chainId={config.CHAIN_ID}
      connectors={
        {
          // This is how connectors get configured
        }
      }
    >
      <div className="App overflow-hidden">
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Home />} />
            </Route>
            <Route path="item" element={<Item />} />

            <Route path="create" element={<Create />} />
            <Route path="user" element={<Profile />} />
            <Route path="editProfile" element={<EditProfile />} />
            <Route path="*" element={<NotFound />} />
            <Route path="explore" element={<Explore />} />
            <Route path="comingSoon" element={<ComingSoon />} />

          </Routes>
        </BrowserRouter>

        <Footer />        <ToastContainer />


      </div>
    </UseWalletProvider>
  );
}

export default App;
