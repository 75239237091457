

const Banners = ()=>{
    return   <div>
    <div className="call2action is__light">
      <div className="container">
        <div className="row justify-content-between align-items-center sm:space-y-20">
          <div className="col-md-6">
            <div className="space-y-20">
              <h1>Start your own collection today</h1>
              <p className="color_text section__text">
              With NFT shell, get started with the world of NFT on Binance Smart chain. Create your first NFT collection today with our platform.
              </p>
              <a href="/create" className="btn btn-primary">Create Art</a>
            </div>
          </div>
          <div className="col-md-auto">
            <img className="img-fluid img__logo" src="assets/img/logos/Logo_illustrstion_white.png" alt="..." />
          </div>
        </div>
      </div>
    </div>
    {/* <div className="container">
      <div className="community">
        <div className="section__head space-y-20">
          <h3 className="section__title text-center">Global community</h3>
          <p className="section__text text-center">
          Explore NFT shell’s marketplace where you can buy, sell and auction some of the rare digital art and collect NFTs.

          </p>
        </div>
        <div className="community__items">
          <div className="row justify-content-center mb-20_reset">
            <div className="col-md-3">
              <div className="item space-y-10">
                <div className="logo">
                  <img src="assets/img/icons/github.svg" alt="logo_community" />
                </div>
                <h5 className="text-center">Github</h5>
                <p className="text-center">
                  Understand the progress of our code and project
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item space-y-10">
                <div className="logo is_twitter">
                  <img src="assets/img/icons/twitter.svg" alt="logo_community" />
                </div>
                <h5 className="text-center">Twitter</h5>
                <p className="text-center">
                  Understand the progress of our code and project
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item space-y-10">
                <div className="logo is_discord">
                  <img src="assets/img/icons/discord.svg" alt="logo_community" />
                </div>
                <h5 className="text-center">Discord</h5>
                <p className="text-center">
                  Understand the progress of our code and project
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </div>
}   

export default Banners