
import Lottie from 'react-lottie';
import comingSoon from '../../assets/lotties/comingSoon.json';


const ComingSoon = ()=>{
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: comingSoon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div>
        <Lottie 
          options={defaultOptions}
        //   height={400}
          width={400}
        />
        <h2 style={{fontSize:80}}>Coming Soon</h2>
      </div>
    );
}

export default ComingSoon