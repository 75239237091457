import { useEffect, useState } from "react";
import Web3 from "web3";
import { getRecentlyMinted } from "../../../apollo/queries";
import NFTCard from "../../../components/NFTCard";
import config from "../../../config";

const Hero = () => {
  const [bigCards, setBigCards] = useState([]);
  const [smallCards,setSmallCards] = useState([])

  useEffect(() => {
    loadNFTs();
  }, []);

  const loadNFTs = async () => {
    const nfts = await getRecentlyMinted(5);
    console.log("nftsss", nfts);
    const _smallCards = []
    const _bigCards = []

    for(let nft of nfts){
      if(_bigCards.length != 2){
        _bigCards.push(nft)
      }else {
        _smallCards.push(nft)
      }
    }
    setBigCards(_bigCards);
    setSmallCards(_smallCards);

  };

  return (
    <div className="hero__3">
      <div className="container">
        <div className="row align-items-center mb-50 md:space-y-20">
          <div className="col-lg-6">
            <h1 className="hero__title">
              <span className="color_brand">Discover</span> rare digital art and
              collect NFTs
            </h1>
          </div>
          <div className="col-lg-6">
            <p className="hero__text color_black">
            Explore NFT shell’s marketplace where you can buy, sell and auction some of the rare digital art and collect NFTs.
            With NFT shell, get started with the world of NFT on Binance Smart chain. Create your first NFT collection today with our platform.
            </p>
          </div>
        </div>
        <div className="wrapper">
          <div className="row">
            {bigCards.map((nft) => {
              return (
                <div className="col-lg-4">
                  <NFTCard data={nft} />
                </div>
              );
            })}

            <div className="col-lg-4">
              {smallCards.map((nft) => {
                return (
                  <div className="card__item five">
                    <div className="card_body space-y-10 space-x-10 d-flex">
                      {/* ???? =============== */}
                      <div className="card_head">
                        <img
                          src={`${config.IPFS_CLIENT}/${nft.metadata.image}`}
                          alt=""
                        />
                        <div className="details d-flex justify-content-between">
                          {/* <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} />
                    </div> */}
                        </div>
                      </div>
                      {/* ???? =============== */}
                      <div
                        className="
                    d-flex
                    flex-column
                    justify-content-center
                    w-100
                    space-y-10
                  "
                      >
                        <h6>
                          <a
                            className="color_black"
                            href={`/item?id=${nft.id}`}
                          >
                            {nft.metadata.name}
                          </a>
                          <p
                            className="avatars_name
              txt_sm cardDescription"
                          >
                            {`${nft.metadata.description}`}
                          </p>
                        </h6>
                        <div className="hr" />
                        <div className="card_footer d-block space-y-10">
                          <a
                            href={`/item?id=${nft.id}`}
                            className="creators space-x-10"
                          >
                            <div className="avatars">
                              <img
                                src="assets/img/avatars/avatar_4.png"
                                alt="Avatar"
                                className="avatar avatar-sm"
                              />
                            </div>
                            <p className="avatars_name txt_sm">
                              {" "}
                              {`${nft.metadata.creator.substring(0, 12)}...`}
                            </p>
                          </a>
                          <div
                            className="
                        d-flex
                        align-items-center
                        space-x-10
                        justify-content-between
                      "
                          >
                            {nft.offer ? (
                              <a
                                className="btn btn-sm btn-primary"
                                href={`/item?id=${nft.id}`}
                              >
                                Buy Now
                              </a>
                            ) : (
                              <a
                                className="btn btn-sm btn-primary"
                                href={`/item?id=${nft.id}`}
                                data-toggle="modal"
                                data-target="#popup_bid"
                              >
                                View Item
                              </a>
                            )}
                            {nft.offer ? (
                              <span className="color_green txt_sm text-right">
                                {Number(
                                  Web3.utils.fromWei(nft.offer.price)
                                ).toFixed(4)}{" "}
                                BNB
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="d-flex justify-content-center mb-30">
                <a href="/explore" className="btn btn-md btn-dark">
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
