import Banners from "./component/Banners"
import Hero from "./component/Hero"
import RecentlyListed from "./component/RecentlyListed"


const Home = ()=>{
    return <>

    <Hero/>
    {/* <RecentlyListed/> */}
    <Banners/>
    
    </>
}


export default Home