import React, { useState, useRef, createRef, useEffect } from "react";
import { uploadFile } from "../../utils/ipfs";
import { useWallet } from "use-wallet";
import { getFactoryContract } from "../../utils/contract";
import { toast } from "react-toastify";

const PreviewPopup = ({ onClose }) => {
  return (
    <div
      className="modal fade popup show"
      id="popup_preview"
      onClick={() => {
        onClose();
      }}
      tabIndex={-1}
      style={{ display: "block", background: "#0000008c" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              onClose();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body space-y-20 p-0">
            <div className="card__item three m-0 in__popup">
              <div className="card_body space-y-10">
                {/* ???? =============== */}
                <div className="card_head">
                  <img src="assets/img/items/item_4.png" alt="" />
                  <a href="Upload.html#" className="likes space-x-3">
                    <i className="ri-heart-3-fill" />
                    <span className="txt_sm">2.1k</span>
                  </a>
                  <div className="action">
                    <a href="Upload.html#" className="btn btn-primary btn-sm">
                      <i className="ri-pie-chart-line color_white" />
                      Place Your Bid
                    </a>
                  </div>
                </div>
                {/* ???? =============== */}
                <h6 className="card_title">Colorful Abstract Painting</h6>
                <div className="card_footer d-block space-y-10">
                  <div className="d-flex justify-content-between">
                    <div className="creators space-x-10">
                      <div className="avatars -space-x-20">
                        <a href="/profile">
                          <img
                            src="assets/img/avatars/avatar_3.png"
                            alt="Avatar"
                            className="avatar
                        avatar-sm"
                          />
                        </a>
                        <a href="/profile">
                          <img
                            src="assets/img/avatars/avatar_2.png"
                            alt="Avatar"
                            className="avatar
                        avatar-sm"
                          />
                        </a>
                      </div>
                      <a href="/profile">
                        <p className="avatars_name txt_sm">@makinzi_jamy... </p>
                      </a>
                    </div>
                    <a href="Upload.html#" className="space-x-3">
                      <p className="color_green txt_sm">0.001 ETH</p>
                    </a>
                  </div>
                  <div className="hr" />
                  <a
                    href="Upload.html#"
                    className="d-flex align-items-center
                space-x-10"
                  >
                    <i className="ri-vip-crown-line" />
                    <p className="color_text txt_sm" style={{ width: "auto" }}>
                      Highest bid
                    </p>
                    <span className="color_black txt_sm">0.001 ETH</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AttributeInput = ({ }) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  

  return (
    <div
      className={`space-y-10 attribute-data`}
      key="hii"
      keyName={key}
      value={value}
    >
      <div>
        <div className="flex">
          <input
            type="text"
            className="form-control mr-1"
            value={key}
            style={{
              width: "auto",
              display: "inline",
            }}
            onChange={(e) => {
              setKey(e.target.value);
              
            }}
            placeholder="e. g. Size"
          />

          <input
            style={{
              // width:"50%",
              display: "inline",
              width: "auto",
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
             
            }}
            type="text"
            className="form-control ml-1"
            placeholder="e.g Large"
          />
        </div>
      </div>
    </div>
  );
};

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


const Create = () => {
  const [isPreview, setPreview] = useState(false);
  const { connect, account } = useWallet();
  const [loading, setLoading] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [selectedMedia, setSelectedMedia] = useState();

  const [selectedMediaFile, setSelectedMediaFile] = useState();

  const factoryContract = getFactoryContract();

  
  const validate = () => {
    return false;
  };



 
  const [attributeList, setAttributeList] = useState([
    <AttributeInput />,
  ]);

  const handleOnCreate = async () => {
    const isError = validate();

    if (isError) {
      return;
    }

    const attributes = []
    var elements = document.getElementsByClassName("attribute-data");
  
    for (let elem of elements) {
      const payload = {
        trait_type:elem.getAttribute("keyName"),
        value:elem.getAttribute("value")
      }
      
      attributes.push(payload)

        // elements[i].style ...
    }

    console.log("attributes",)

   
    console.log("Wsaasasassas", attributes);


    setLoading(true);
    const imageReceipt = await uploadFile(selectedMediaFile);

    const payload = {
      name: title,
      description: description,
      image: imageReceipt.path,
      creator: account,
      createdAt:Math.floor(Date.now() ),
      attributes:attributes
    };

    const metaReceipt = await uploadFile(JSON.stringify(payload));
    try {
      await factoryContract.methods.mint(metaReceipt.path).send({ from: account });

      toast("NFT Minted!", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (err) {}
    console.log("hasssss", metaReceipt.path);
    setLoading(false);
    window.location=`/user?account=${account}`
  };

  const addProperty = () => {
    // const _ref = createRef()
    setAttributeList([
      ...attributeList,
      <AttributeInput  />,
    ]);
  };

  return (
    <div className="container">
      <div className="box in__upload mb-120 mt-120">
        <div className="row">
          <div className="col-lg-6">
            <div className="left__part space-y-40 md:mb-20 upload_file">
              {selectedMedia ? (
                <div className="space-y-20">
                  <img
                    className="icon"
                    src={selectedMedia}
                    style={{
                      borderRadius: 15,
                    }}
                    id="upload_file"
                    alt=""
                  />
                  <input
                    type="file"
                    id="imgUpload"
                    accept="image/*"
                    onChange={(e) => {
                      e.preventDefault();
                      setSelectedMedia(URL.createObjectURL(e.target.files[0]));
                      setSelectedMediaFile(e.target.files[0]);
                      // const reader = new window.FileReader();
                      // reader.readAsArrayBuffer(e.target.files[0]); // convert file to array for buffer
                      // reader.onloadend = () => {
                      //   setSelectedMediaFile(Buffer(reader.result));
                      // };
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="space-y-20">
                    <img
                      className="icon"
                      src="assets/img/icons/upload.svg"
                      alt=""
                    />
                    {/* <h5>Drag and drop your file</h5> */}
                    <p className="color_text">
                      PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                    </p>
                  </div>
                  <div className="space-y-20">
                    <p className="color_text">or choose a file</p>
                    <a href="#" className="btn btn-white">
                      {" "}
                      Browse files{" "}
                    </a>
                    <input
                      type="file"
                      id="imgUpload"
                      accept="image/*"
                      onChange={(e) => {
                        setSelectedMedia(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setSelectedMediaFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group space-y-10">
              <div className="space-y-20">
                <div className="space-y-10">
                  <span className="nameInput">Title</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    value={title}
                    className="form-control"
                    placeholder="e. g. `NFTSHELL design art`"
                  />
                </div>
                <div className="space-y-10">
                  <span className="nameInput">
                    Description <span className="color_text">(optional) </span>
                  </span>
                  <input
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    value={description}
                    type="text"
                    className="form-control"
                    placeholder="e. g. `NFTSHELL design art`"
                  />
                </div>
                <span className="nameInput">Properties (optional)</span>

                {attributeList}
                <a
                  className="btn btn-grad btn-sm mr-1"
                  href="#"
                  onClick={() => {
                    addProperty();
                  }}
                >
                  Add Properties
                </a>

                {attributeList.length > 1 ? (
                  <a
                    className="btn btn-grad btn-sm mr-1"
                    href="#"
                    onClick={() => {
                      setAttributeList([...attributeList.slice(0, -1)]);
                    }}
                  >
                    Remove Properties
                  </a>
                ) : null}
              </div>
              {account ? (
                <a
                  className="btn btn-grad btn-lg w-full"
                  href="#"
                  onClick={() => {
                    if (!loading) {
                      handleOnCreate();
                    }
                  }}
                >
                  {loading ? "Loading..." : "Create Art"}
                </a>
              ) : (
                <a
                  className="btn btn-grad btn-lg w-full"
                  href="#"
                  onClick={() => {
                    connect();
                  }}
                >
                  Connect Wallet
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {isPreview ? (
        <PreviewPopup
          onClose={() => {
            setPreview(false);
          }}
        />
      ) : null} */}

      {/* <div className="fixed_row bottom-0 left-0 right-0">
        <div className="container">
          <div className="row content justify-content-between mb-20_reset">
            <div className="col-md-auto col-12 mb-20">
              <div className="space-x-10">
                <a
                  href="/"
                  className="btn btn-white
									others_btn"
                >
                  {" "}
                  Cancel
                </a>
                <a
                  href="#"
                  className="btn btn-dark others_btn"
                  onClick={() => {
                    setPreview(!isPreview);
                  }}
                >
                  {" "}
                  Preview
                </a>
              </div>
            </div>
            <div className="col-md-auto col-12 mb-20">
              <a
                href="/item"
                className="btn btn-grad
								btn_create"
              >
                {" "}
                Create item
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Create;
