import { useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import { getMarketPlaceContract } from "../../utils/contract";

const Header = () => {
  const { connect, account, networkName } = useWallet();
  const [withdrawBalance, setWithdrawBalance] = useState(0);

  const [withdrawing, setWithdrawing] = useState(false);

  const getShortWallet = () => {
    return `${account.substring(0, 4)}...${account.substring(
      account.length - 4,
      account.length
    )}`;
  };

  useEffect(() => {
    if (account) {
      loadWithdrawBalance();
    }
  }, [account]);

  const loadWithdrawBalance = async () => {
    setInterval(async () => {
      let bal = await getMarketPlaceContract()
        .methods.userFunds(account)
        .call();
      if (bal == 0) {
        setWithdrawBalance(Number(Web3.utils.fromWei(bal)));
      } else {
        setWithdrawBalance(Number(Web3.utils.fromWei(bal)).toFixed(4));
      }
    }, 4000);
  };

  const onWithdrawFund = async () => {
    setWithdrawing(true);

    try {
      await getMarketPlaceContract()
        .methods.claimFunds()
        .send({ from: account });
    } catch (err) {}
    setWithdrawing(false);
  };

  useEffect(() => {
    connect();
  }, []);

  return (
    <div>
      <header className="header__1 js-header" id="header">
        <div className="container">
          <div className="wrapper js-header-wrapper">
            <div className="header__logo">
              <a href="/">
                <img
                  className="header__logo"
                  id="logo_js"
                  src="/logos/logo.png"
                  alt="logo"
                />
              </a>
            </div>
            {/* ==================  */}
            <div className="header__menu">
              <ul className="d-flex space-x-20">
                <li>
                  <a className="color_black" href="/">
                    Home
                  </a>
                </li>

                <li>
                  <a className="color_black" href="/explore">
                    Explore
                  </a>
                </li>

                <li>
                  <a className="color_black" href="/comingsoon">
                    NFT Lending
                  </a>
                </li>
                {account ? (
                  <li>
                    <a
                      className="color_black"
                      href={`/user?account=${account}`}
                    >
                      Your Profile
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>

            <div className="header__btns">
              <a
                className="btn btn-grad btn-sm"
                onClick={() => {
                  connect();
                }}
              >
                <i className="ri-wallet-3-line" />
                {account ? getShortWallet() : "Connect wallet"}
              </a>

              <a
                className="btn btn-grad btn-sm "
                style={{
                  marginLeft: 10,
                }}
                href="/create"
              >
                <i className="bi-plus-lg" />
                Create
              </a>

              {account ? (
                <a
                  className="btn btn-outline btn-sm "
                  onClick={() => {
                    onWithdrawFund();
                  }}
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <i className="bi-plus-lg" />

                  {withdrawing ? (
                    <div className="spinner-border"></div>
                  ) : (
                    `Funds | ${withdrawBalance} BNB`
                  )}
                </a>
              ) : null}
            </div>

            <div className="header__burger js-header-burger" />
            <div className="header__mobile js-header-mobile">
              <div className="header__mobile__menu space-y-40">
                <ul className="d-flex space-y-20">
                  <li>
                    <a className="color_black" href="/">
                      Home
                    </a>
                  </li>
                  <li>
                    <a className="color_black" href="/explore">
                      Explore
                    </a>
                  </li>
                  {account ? (
                    <li>
                      <a
                        className="color_black"
                        href={`/user?account=${account}`}
                      >
                        Your Profile
                      </a>
                    </li>
                  ) : null}
                </ul>
                <div className="space-y-20">
                  <a
                    className="btn btn-grad btn-sm"
                    onClick={() => {
                      connect();
                    }}
                  >
                    {account ? getShortWallet() : "Connect wallet"}
                  </a>
                </div>
                <div className="space-y-20">
                  <a className="btn btn-grad btn-sm" href="/create">
                    Create
                  </a>
                </div>

                {account ? (
                  <div className="space-y-20">
                    <a
                      className="btn btn-grad btn-sm"
                      onClick={() => {
                        onWithdrawFund();
                      }}
                    >
                      {withdrawing ? (
                        <div className="spinner-border"></div>
                      ) : (
                        `Funds | ${withdrawBalance} BNB`
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
