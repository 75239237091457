

import Web3 from "web3"
import FACTORY_ABI  from "../abis/NFTFactory.json"
import MARKET_PLACE_ABI  from "../abis/MARKETPLACE.json"

import config from "../config";


export const getWeb3 = ()=>{
    const web3 = new Web3(Web3.givenProvider);
    return web3;
}

export const getContract=(abi,address)=>{
   return new getWeb3().eth.Contract(abi,address)
}


export const getFactoryContract=()=>{
    const web3 = getWeb3()
    return new web3.eth.Contract(FACTORY_ABI,config.NFT_FACTORY)
 }
 


export const getMarketPlaceContract=()=>{
    const web3 = getWeb3()
    return new web3.eth.Contract(MARKET_PLACE_ABI,config.NFT_MARKETPLACE)
 }
 