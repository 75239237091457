import { useEffect, useState } from "react";
import ComingSoon from "../../components/ComingSoon";
import { getRecentlyMinted } from "../../apollo/queries";
import NFTCard from "../../components/NFTCard";

const Explore = () => {
  const [allNFTs, setAllNFTs] = useState([]);
  const [nfts, setNFTs] = useState([]);
  const [onlyAvailableForSell, setOnlyAvailableForSell] = useState(false);

  useEffect(() => {
    loadNFTs();
  }, []);

  const loadNFTs = async () => {
    const nfts = await getRecentlyMinted(100);
    setAllNFTs(nfts);
    setNFTs(nfts);
  };


  useEffect(()=>{
    let nfts = []
    if(onlyAvailableForSell){
      nfts = allNFTs.filter((nft)=> nft.offer)
    }else{
      nfts = allNFTs;
    }

    setNFTs(nfts)
  },[onlyAvailableForSell])

  //   return <>

  // <div className="hero__2">
  //     <div className="container">
  //     <div className="space-y-20">
  //     <ComingSoon/>

  //   <h1 style={{
  //     fontSize:50
  //   }}>
  //     Coming Soon
  //   </h1>
  //     </div>

  // </div>
  //     </div>

  //   </>
  return (
    <div>
      <div className="section mt-100" />
      <div className="container">
        <div className="row md:space-y-30">
          <div className="col-lg-12">
            <div className="section__head">
              <h2 className="section__title mb-20">Recent NFTs</h2>
              <div className="d-flex">
                <span
                  className="color_text txt_sm d-none d-sm-block mr-10"
                  style={{ minWidth: "max-content" }}
                >
                  {" "}
                  FILTER BY:{" "}
                </span>
                <ul className="menu_categories">
                  <li className="d-flex space-x-10 switch_item">
                    <input
                      type="checkbox"
                      id="switch1"
                      value={onlyAvailableForSell}
                      onClick={() => {
                        setOnlyAvailableForSell(!onlyAvailableForSell);
                      }}
                    />
                    <label htmlFor="switch1" className="ml-0">
                      Toggle
                    </label>
                    <span> Available for Sale </span>
                  </li>
                  {/* <li className="d-flex space-x-10 switch_item">
                    <input type="checkbox" id="switch2" />
                    <label htmlFor="switch2" className="ml-0">
                      Toggle
                    </label>
                    <span> Has open offer </span>
                  </li>
                  <li className="d-flex space-x-10 switch_item">
                    <input type="checkbox" id="switch3" />
                    <label htmlFor="switch3" className="ml-0">
                      Toggle
                    </label>
                    <span> Has sold </span>
                  </li> */}
                </ul>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "table",
              }}
            >
              {nfts.map((nft) => {
                return (
                  <div
                    className="col-lg-4"
                    style={{
                      float: "none",
                      display: "table-cell",
                      verticalAlign: "top",
                    }}
                  >
                    <NFTCard data={nft} />
                  </div>
                );

                // return <NFTCard data={nft}/>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
