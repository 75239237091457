import { useEffect, useState } from "react";
// import { getMyNfts } from "../../utils/moralis";
import { useWallet } from "use-wallet";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getWeb3 } from "../../utils/contract";
import { copyToClipboard } from "../../utils/utils";
import NFTCard from "../../components/NFTCard";
import {getMyNFTs} from "../../apollo/queries"


const Profile = () => {
  const [myNFTS, setMyNFTs] = useState([]);
  const search = useLocation().search;
  const account = new URLSearchParams(search).get("account");

  useEffect(() => {
    if (account) {
      if (getWeb3().utils.isAddress(account)) {
        loadMyNFTs();
      } else {
        goTo404();
      }
    } else {
      goTo404();
    }
  }, [account]);

  const goTo404 = () => {
    window.location = "/404";
  };

  const loadMyNFTs = async () => {
    const items = await getMyNFTs(account);
    console.log("uteeeeeee",items)
    setMyNFTs(items);
    console.log("iteeee", items);
  };

  return (
    <div>
      <div className="mb-100">
        <div className="hero__profile">
          <div className="cover">
            <img src="assets/img/bg/prrofile.png" alt="" />
          </div>
          <div className="infos">
            <div className="container">
              <div className="row flex-wrap align-items-center justify-content-between sm:space-y-50">
                <div className="col-md-auto mr-20">
                  <div
                    className="avatars d-flex space-x-20
                  align-items-center"
                  >
                    <div className="avatar_wrap">
                      <img
                        className="avatar avatar-lg"
                        src="assets/img/avatars/avatar_4.png"
                        alt="avatar"
                      />
                    </div>
                    {/* <h5>@ayoub fennouni</h5> */}
                  </div>
                </div>
                <div className="col-md-auto">
                  <div
                    className="d-sm-flex flex-wrap align-items-center
                  space-x-20 mb-20_reset d-sm-block"
                  >
                    <div className="mb-20">
                      <div className="copy">
                        <span className="color_text"> {account}</span>
                        <a
                          onClick={() => {
                            copyToClipboard(account);
                          }}
                        >
                          <i className="ri-file-copy-line color_text" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap align-items-center
                    space-x-20"
                    >
                      <div className="mb-20">
                        <div className="share">
                          <div className="icon">
                            <a href="#">
                              {" "}
                              <i className="ri-share-line" />
                            </a>
                          </div>
                          <div className="dropdown__popup">
                            <ul className="space-y-10">
                              <li>
                                {" "}
                                <a>
                                  {" "}
                                  <i className="ri-facebook-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a>
                                  {" "}
                                  <i className="ri-messenger-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a>
                                  {" "}
                                  <i className="ri-whatsapp-line" />
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a>
                                  {" "}
                                  <i className="ri-youtube-line" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="mb-20">
                        <div className="more">
                          <div className="icon">
                            <a>
                              {" "}
                              <i className="ri-more-fill" />
                            </a>
                          </div>
                          <div className="dropdown__popup">
                            <ul className="space-y-10">
                              <li>
                                <a
                                  href="/profile#"
                                  className="space-x-10
                                d-flex"
                                >
                                  <i className="ri-flag-line" />
                                  <span> Report </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-7 order-md-0 order-1">
            {/* <div className="profile__sidebar">
              <div className="space-y-40">
                <div className="space-y-10">
                  <h5>About me</h5>
                  <div className="box space-y-20">
                    <p>
                      I make art with the simple goal of giving you
                      something
                      pleasing to look at for a few seconds.
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <span className="txt_sm color_text">Collections</span>
                        <h4>105</h4>
                      </div>
                      <div className="col-6">
                        <span className="txt_sm color_text">Creations</span>
                        <h4>406</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-10">
                  <h5>Follow me</h5>
                  <div className="box">
                    <ul className="social_profile space-y-10 overflow-hidden">
                      <li>
                        <a href="/profile#">
                          <i className="ri-facebook-line" />
                          <span className="color_text">facebook/</span>
                          @creabik
                        </a>
                      </li>
                      <li>
                        <a href="/profile#">
                          <i className="ri-messenger-line" />
                          <span className="color_text"> messenger/</span>
                          @creabik
                        </a>
                      </li>
                      <li>
                        <a href="/profile#">
                          <i className="ri-whatsapp-line" />
                          <span className="color_text"> whatsapp/</span>
                          @creabik
                        </a>
                      </li>
                      <li>
                        <a href="/profile#">
                          <i className="ri-youtube-line" />
                          <span className="color_text">youtube/</span>
                          @creabik
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="text-center txt_sm mt-20 color_text">Since 2021</p>
            </div> */}
          </div>
          <div className="col-lg-9 col-md-12 order-md-1 order-0">
            <div className="profile__content">
              <div className="d-flex justify-content-between">
                <div className="space-x-10">
                  <div className="d-flex justify-content-between">
                    <ul
                      className="nav nav-tabs d-flex space-x-10 mb-30"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="btn btn-white btn-sm active"
                          data-toggle="tab"
                          href="/profile#tabs-1"
                          role="tab"
                        >
                          On Sale
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="btn btn-white btn-sm"
                          data-toggle="tab"
                          href="/profile#tabs-2"
                          role="tab"
                        >
                          Own
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="tabs-1"
                      role="tabpanel"

                    >
                   <div className="container">
                   <div className="row  mb-30_reset row-eq-height" style={{
                        // display:"table",
                        // width: "100%"

                      }}>

                        {myNFTS.length == 1?<NFTCard data={myNFTS[0]} /> :myNFTS.map((nft) => {
                          return (
                            <div className="col-lg-4 col-md-12 col-sm-12" 
                            style={{
                              // display:"table-cell"

                            }}>
                             
                              <NFTCard data={nft} />
                            </div>
                          );
                        })}
                      </div>

                   </div>

                      {/* <div class="grid grid-cols-4 gap-4  d-flex" style={{}}>
                        {myNFTS.map((nft) => {
                          return (
                            <NFTCard data={nft} />
                          );
                        })}
                      </div> */}
                    </div>

                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                      <div className="row justify-content-center mb-30_reset">
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_1.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_2.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_3.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_4.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">Creative Art collection</a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">2.1k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                5 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_1.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @william_jamy...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_5.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_6.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_7.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_8.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">
                                  Colorful Abstract collection
                                </a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">3.5k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                7 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_2.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @alexis_fenn...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_2.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_6.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_3.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_7.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">
                                  Photography Art collection
                                </a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">7.2k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                2 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_3.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @Joshua_Bren...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_1.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_2.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_3.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_4.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">Fantasy Art collection</a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">2.1k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                5 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_4.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @william_jamy...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_5.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_6.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_7.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_8.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">Pop Art collection</a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">3.5k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                7 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_5.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @alexis_fenn...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8">
                          <div className="collections space-y-10 mb-30">
                            <div className="collections_item">
                              <div className="images-box space-y-10">
                                <div className="d-flex space-x-10">
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_2.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_6.png"
                                    alt=""
                                  />
                                  <img
                                    style={{ width: "33.33%" }}
                                    src="assets/img/items/item_3.png"
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src="assets/img/items/item_7.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="collections_footer justify-content-between">
                              <h5 className="collection_title">
                                <a href="/profile">
                                  Contemporary Art collection
                                </a>
                              </h5>
                              <a href="/profile#" className="likes space-x-3">
                                <i className="ri-heart-3-fill" />
                                <span className="txt_md">7.2k</span>
                              </a>
                            </div>
                            <div className="creators space-x-10">
                              <span className="color_text txt_md">
                                {" "}
                                2 items · Created by
                              </span>
                              <div className="avatars space-x-5">
                                <a href="/profile">
                                  <img
                                    src="assets/img/avatars/avatar_6.png"
                                    alt="Avatar"
                                    className="avatar avatar-sm"
                                  />
                                </a>
                              </div>
                              <a href="/profile">
                                <p className="avatars_name txt_sm">
                                  {" "}
                                  @Joshua_Bren...{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
