

const EditProfile = ()=>{

    return  <div className="edit_profile">
   
    <div className="mb-50">
      <div className="hero__profile">
        <div className="tabs">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb default mb-0">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Edit profile</li>
            </ol>
          </nav>
        </div>
        <div className="cover">
          <img src="assets/img/bg/cover.jpg" alt="cover" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="mb-50">
        <h3 className="mb-30">Choice your Cover image</h3>
        <div className="row profile-img">
          <div className="col-6 col-md-2">
            <div className="box
                                in__profile
                                d-flex
                                justify-content-center
                                align-items-center">
              <img className="icon" src="assets/img/icons/upload-plus.svg" alt="" />
              <input type="file" accept="image/png, image/jpeg" />
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="pro_img is_active">
              <img src="assets/img/bg/cover_active.png" alt="" />
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="pro_img">
              <img src="assets/img/bg/edit1.png" alt="" />
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="pro_img">
              <img src="assets/img/bg/edit1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="avatars space-x-20 mb-30">
          <div id="profile-container">
            <img id="profileImage" src="assets/img/avatars/avatar_3.png" alt="Avatar" className="avatar avatar-lg border-0" />
          </div>
          <div className="space-x-10 d-flex">
            <div id="boxUpload">
              <a href="edit_/profile#" className="btn btn-dark">
                Upload New Photo </a>
              <input id="imageUpload" type="file" name="profile_photo" placeholder="Photo" required capture />
            </div>
            <a href="edit_/profile#" className="btn btn-white"> Delete </a>
          </div>
        </div>
        <div className="box edit_box col-lg-9 space-y-30">
          <div className="row sm:space-y-20">
            <div className="col-lg-6 account-info">
              <h3 className="mb-20">Account info 🍉</h3>
              <div className="form-group space-y-10 mb-0">
                <div className="space-y-40">
                  <div className="space-y-10">
                    <span className="nameInput">Display name</span>
                    <input type="text" className="form-control" defaultValue="Creabik" />
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Custom URL</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput d-flex
                                                justify-content-between">Email
                      <span className="txt_xs">Your email for
                        marketplace notifications</span>
                    </span>
                    <div className="confirm">
                      <input type="text" className="form-control" placeholder="Enter your email" />
                      <a href="edit_/profile#" className="confirm-btn btn
                                                    btn-dark btn-sm">Confirm</a>
                    </div>
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Bio</span>
                    <textarea style={{minHeight: '110px'}} placeholder="Add your bio" defaultValue={"\t\t\t\n\t\t\t                                    "} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 social-media">
              <h3 className="mb-20">Your Social media</h3>
              <div className="form-group space-y-10">
                <div className="space-y-40">
                  <div className="d-flex flex-column">
                    <span className="nameInput mb-10">Facebook</span>
                    <input type="text" className="form-control" placeholder="facebook username" />
                    <a className="facebook-btn btn btn-primary mt-20
                                                btn-sm" href="edit_/profile#">
                      <i className="ri-facebook-circle-fill" />
                      Connect to Facebook
                    </a>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="nameInput mb-10">Twitter</span>
                    <input type="text" className="form-control" placeholder="twitter username" />
                    <a className="twitter-btn btn btn-primary mt-20
                                                btn-sm" href="edit_/profile#">
                      <i className="ri-twitter-fill" />
                      Connect to Twitter
                    </a>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="nameInput mb-10">Discord</span>
                    <input type="text" className="form-control" placeholder="discord username" />
                    <a className="discord-btn btn btn-primary mt-20
                                                btn-sm" href="edit_/profile#">
                      <i className="ri-discord-fill" />
                      Connect to Discord
                    </a>
                  </div>
                </div>
              </div>
              <a className="btn btn-white mt-20
                                    btn-sm" href="edit_/profile#" data-toggle="modal" data-target="#popup_social_media">
                <i className="ri-add-circle-line color_brand" />
                Add more Social media
              </a>
            </div>
          </div>
          <div className="hr" />
          <p className="color_black">To update your settings you should
            sign message through your wallet. Click 'Update profile'
            then sign the message.</p>
          <div><a href="edit_/profile#" className="btn btn-grad">Update Profile</a></div>
        </div>
      </div>
    </div>
  </div> 
}


export default EditProfile